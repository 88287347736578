import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { signup, login } from "../actions/Auth";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
const AuthPage = () => {
  const [selectedTab, setSelectedTab] = useState("login");

  const { loading } = useSelector((state) => state.auth);
  return (
    <section>
      <Container>
        <div className="auth-container">
          {loading && <Loader />}
          <div className="auth-card">
            <div className="auth-header">
              {selectedTab === "login" ? "Login" : "Register"}
            </div>
            <div className="auth-body">
              <Row className="gy-4">
                <Col xs={12}>
                  {selectedTab === "login" ? (
                    <LoginForm />
                  ) : (
                    <SignupForm setSelectedTab={setSelectedTab} />
                  )}
                </Col>
                <Col xs={12}>
                  <div className="text-end">
                    {selectedTab === "login" ? (
                      <p>
                        Don't have an account?{" "}
                        <span
                          className="link"
                          onClick={() => setSelectedTab("signup")}
                        >
                          Register.
                        </span>
                      </p>
                    ) : (
                      <p>
                        Already have an account?{" "}
                        <span
                          className="link"
                          onClick={() => setSelectedTab("login")}
                        >
                          Login.
                        </span>
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const LoginForm = () => {
  const dispatch = useDispatch(); // Initialize useDispatch
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const loginData = {
      email,
      password,
    };
    login(loginData, setFormData, dispatch, navigate);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Row className="gy-3">
        <Col xs={12}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleOnChange}
              required
            />
          </div>
        </Col>
        <Col xs={12}>
          <div className="form-group">
            <label>Password:</label>
            <div className="form-password">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleOnChange}
                required
              />
              <button type="button" onClick={toggleShowPassword}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <button type="submit" className="btn btn-primary">
            Login
          </button>
        </Col>
      </Row>
    </form>
  );
};

const SignupForm = ({ setSelectedTab }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    contactNumber: "",
  });
  const {
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    contactNumber,
  } = formData;
  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match");
      return;
    }
    const signupData = {
      ...formData,
    };
    // console.log(signupData);
    const result = signup(
      formData,
      setFormData,
      setSelectedTab,
      dispatch,
      setLoading
    );
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Row className="gy-3">
        <Col xs={12}>
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={firstName}
              onChange={handleOnChange}
              required
            />
          </div>
        </Col>
        <Col xs={12}>
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={lastName}
              onChange={handleOnChange}
              required
            />
          </div>
        </Col>
        <Col xs={12}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleOnChange}
              required
            />
          </div>
        </Col>
        <Col xs={12}>
          <div className="form-group">
            <label>Password:</label>
            <div className="form-password">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={handleOnChange}
                required
                value={password}
              />
              <button type="button" onClick={toggleShowPassword}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <div className="form-group">
            <label>Confirm Password:</label>
            <div className="form-password">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                onChange={handleOnChange}
                required
                value={confirmPassword}
              />
              <button type="button" onClick={toggleShowConfirmPassword}>
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <div className="form-group">
            <label>Contact No:</label>
            <input
              type="number"
              name="contactNumber"
              onChange={handleOnChange}
              required
              value={contactNumber}
            />
          </div>
        </Col>
        <Col xs={12}>
          <button type="submit" className="btn btn-primary">
            Register
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default AuthPage;
