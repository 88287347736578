import React from "react";
import EmailList from "./EmailList";
import UploadForm from "./UploadForm";
import PendingInfoPage from "./PendingSection";
import { Col, Container, Row } from "react-bootstrap";

export const EmailTab = () => {
  return (
    <div className="section">
      <Container>
        {/* <h1>Email Sender</h1> */}
        <Row className="gy-5">
          <Col xs={12}>
            <UploadForm />
          </Col>
          <Col xs={12}>
            <PendingInfoPage />
          </Col>
          <Col xs={12}>
            <EmailList />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
