// src/components/EmailList.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Loader from "./Loader";
import { fetchEmails, fetchEmailsCount } from "../actions/Emails";
import ContentLoader from "./ContentLoader";
import { setEmails } from "../slices/emailSlice";

const EmailList = () => {
  const dispatch = useDispatch();
  const { emails, loading } = useSelector((state) => state.email);
  const { updateEmails } = useSelector((state) => state.upload);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEmails, setTotalEmails] = useState(0);
  const [expandedRows, setExpandedRows] = useState({});
  const emailsPerPage = 50;
  const [isCountFetched, setIsCountFetched] = useState(false);
  const { token } = useSelector((state) => state.auth);
  if (updateEmails) {
    fetchEmailsCount(token, dispatch, setTotalEmails);
  }
  useEffect(() => {
    // console.log("CurrentPage", currentPage);
    if (isCountFetched) {
      dispatch(setEmails([]));
      fetchEmails(token, dispatch, currentPage + 1);
    }
  }, [currentPage, isCountFetched]);
  useEffect(() => {
    // console.log(expandedRows);
    fetchEmailsCount(token, dispatch, setTotalEmails);
    setIsCountFetched(true);
  }, [setTotalEmails]);

  const handlePageClick = (page) => {
    // if (!loading) {
    setCurrentPage(page.selected);
    // }
  };

  const toggleReadMore = (index, field) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [field]: !prevState[index]?.[field],
      },
    }));
  };

  // const offset = currentPage * emailsPerPage;
  // const currentEmails = Array.isArray(emails)
  //   ? emails.slice(offset, offset + emailsPerPage)
  //   : [];
  let srNo = 1;
  return (
    <div className="auth-card card-full">
      <div className="auth-header">Recently Sended Email List</div>
      <div className="auth-body">
        {/* {loading ? (
          // <Loader />
          <div></div>
        ) : ( */}
        <>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr no</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Subject</th>
                  <th>Body</th>
                  <th>Status</th>
                  <th>FileName</th>
                </tr>
              </thead>
              <tbody>
                {emails.length > 0 ? (
                  emails.map((email, index) => (
                    <tr key={index}>
                      <td>{srNo++}</td>
                      <td>{email.fromEmail}</td>
                      <td>{email.toEmail}</td>
                      <td>
                        {expandedRows[index]?.subject
                          ? email.subject
                          : `${email.subject.substring(0, 50)}`}
                        {email.subject.length > 50 && (
                          <span
                            className="read-more"
                            onClick={() => toggleReadMore(index, "subject")}
                          >
                            {expandedRows[index]?.subject
                              ? "Read Less"
                              : "Read More"}
                          </span>
                        )}
                      </td>
                      <td>
                        {expandedRows[index]?.body
                          ? email.newBody
                          : `${email.newBody.substring(0, 50)}`}
                        {email.newBody.length > 50 && (
                          <span
                            className="read-more"
                            onClick={() => toggleReadMore(index, "body")}
                          >
                            {expandedRows[index]?.body
                              ? "Read Less"
                              : "Read More"}
                          </span>
                        )}
                      </td>
                      <td>{email.status}</td>
                      <td>{email.upload_filename}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <ContentLoader />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {emails.length > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={Math.ceil(totalEmails / emailsPerPage)}
                // pageCount={Math.ceil(
                //   Array.isArray(emails) ? emails.length / emailsPerPage : 0
                // )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                initialPage={currentPage}
              />
            )}
          </div>
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default EmailList;
