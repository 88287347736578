import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  updateEmails: false,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setUpdateEmails(state, value) {
      state.updateEmails = value.payload;
    },
  },
});
export const { setLoading, setUpdateEmails } = uploadSlice.actions;
export default uploadSlice.reducer;
