import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emails: [],
  loading: false,
};

const emailSlice = createSlice({
  name: "email",
  initialState: initialState,
  reducers: {
    setEmails(state, value) {
      state.emails = value.payload;
    },
    setLoading(state, value) {
      state.loading = value.payload;
    },
  },
});
export const { setEmails, setLoading } = emailSlice.actions;
export default emailSlice.reducer;