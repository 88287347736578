import toast from "react-hot-toast";
import { apiConnector } from "../services/apiConnector";
import { authEndpoints } from "../services/apis";
import {
  setToken,
  setIsTokenAvailable,
  setLoading,
  setPendingData,
  setLastUploadData,
} from "../slices/authSlice";
const { SIGNUP, LOGIN, PENDING_INFO, TEST } = authEndpoints;

export const signup = async (
  formData,
  setFormData,
  setSelectedTab,
  dispatch,
  setLoading
) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConnector("POST", SIGNUP, formData);
    // console.log(response);
    if (response.data.success) {
      toast.success(response?.data?.message);
    }
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      contactNumber: "",
    });
    setSelectedTab("login");
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    if (error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(error.message);
    }
    dispatch(setLoading(false));
  }
};

export const login = async (loginData, setFormData, dispatch, navigate) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConnector("POST", LOGIN, loginData);
    // console.log(response);
    if (response?.data?.success) {
      toast.success(response?.data?.message);
    }
    setFormData({
      email: "",
      password: "",
    });
    dispatch(setToken(response?.data?.token));
    dispatch(setIsTokenAvailable(true));
    localStorage.setItem("token", JSON.stringify(response?.data?.token));
    dispatch(setLoading(false));
    navigate("/");
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.message);
    }
    dispatch(setLoading(false));
  }
};

export const fetchPendingInfo = async (token, dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConnector("GET", PENDING_INFO, null, {
      Authorization: `Bearer ${token}`,
    });
    // console.log(response);
    // if (response.data.success) {
    //   toast.success(response?.data?.message);
    // }
    dispatch(setPendingData(response?.data?.pendingData));
    dispatch(setLastUploadData(response?.data?.lastUploadData));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      if (error?.response?.data?.message !== "Jwt token is expired") {
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error(error?.message);
    }
    dispatch(setLoading(false));
  }
};

export const checkAuth = async (token, dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConnector("GET", TEST, null, {
      Authorization: `Bearer ${token}`,
    });
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message === "Jwt token is expired") {
      localStorage.removeItem("token");
      dispatch(setIsTokenAvailable(false));
      dispatch(setToken(null));
      toast.error("Session is expired please login again");
    } else {
      toast.error(error?.message);
    }
    dispatch(setLoading(false));
  }
};
