// src/App.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthPage from "./components/Auth";
import "./App.css";
import { checkAuth } from "./actions/Auth";
import { EmailTab } from "./components/EmailTab";
import { SMSTab } from "./components/SMSTab";
import { Container } from "react-bootstrap";

const App = () => {
  const { isTokenAvailable } = useSelector((state) => state.auth);
  const [selectedTab, setSelectedTab] = useState("Email");
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    async function auth() {
      await checkAuth(token, dispatch);
    }
    if (isTokenAvailable) {
      auth();
    }
  }, [isTokenAvailable]);
  return (
    <div className="App">
      {/* <h1>Email Sender</h1> */}
      {!isTokenAvailable ? (
        <AuthPage />
      ) : (
        <div>
          <div>
            {/* <form>
              <label>
                <input
                  type="radio"
                  name="whatToSend"
                  value="Email"
                  checked={selectedTab === "Email"}
                  onChange={(e) => setSelectedTab(e.target.value)}
                />
                Email
              </label>
              <label>
                <input
                  type="radio"
                  name="whatToSend"
                  value="SMS"
                  checked={selectedTab === "SMS"}
                  onChange={(e) => setSelectedTab(e.target.value)}
                />
                SMS
              </label>
            </form> */}

            <Container>
              <div className="tab-container">
                <button
                  type="button"
                  className={`tab-btn ${
                    selectedTab === "Email" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Email")}
                >
                  Email
                </button>
                <button
                  type="button"
                  className={`tab-btn ${selectedTab === "SMS" ? "active" : ""}`}
                  onClick={() => setSelectedTab("SMS")}
                >
                  SMS
                </button>
              </div>
            </Container>
          </div>
          {selectedTab === "Email" && <EmailTab />}
          {selectedTab === "SMS" && <SMSTab />}
        </div>
      )}
    </div>
  );
};

export default App;
