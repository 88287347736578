import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { downloadFile, getSmsData, reqLogFile, sendSMS } from "../actions/Sms";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import ReactPaginate from "react-paginate";
// import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";

export const SMSTab = () => {
  const templates = [
    {
      templateName: "Allotment SMS",
      senderId: "ACCRTA",
      templateId: "66bf5529d6fc052bf3008442",
      smsBody:
        "Informed your Bank to Block Rs. ##rupees## for ##shares## due to Allotment. Visit our website. Accurate RTA",
      variables: ["rupees", "shares"],
    },
    {
      templateName: "Refund SMS",
      senderId: "ACCRTA",
      templateId: "66bc8275d6fc057d0702f9a2",
      smsBody:
        "Informed your Bank to Unblock Rs. ##rupees## for ##shares## due to Non-Allotment. Visit our website. Accurate RTA",
      variables: ["rupees", "shares"],
    },
  ];
  const dispatch = useDispatch();
  const { loading, smsData, updateSms } = useSelector((state) => state.sms);
  const { token } = useSelector((state) => state.auth);
  // const [smsData, setSmsData] = useState();
  const [formData, setFormData] = useState({
    csvFile: null,
    templateName: "",
    senderId: "",
    templateId: "",
    smsBody: "",
    selectedFields: [],
  });

  const [fileData, setFileData] = useState([]);
  const [variables, setVariables] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedRows, setExpandedRows] = useState({});
  const smsPerPage = 10;
  const handlePageClick = (smsData) => {
    setCurrentPage(smsData.selected);
  };

  const toggleReadMore = (index, field) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [field]: !prevState[index]?.[field],
      },
    }));
  };

  const offset = currentPage * smsPerPage;
  const currentSms = Array.isArray(smsData)
    ? smsData.slice(offset, offset + smsPerPage)
    : [];
  let srNo = 1;
  const handleTemplateChange = (event) => {
    const selectedTemplateName = event.target.value;
    if (selectedTemplateName === "") {
      setFormData({
        ...formData,
        templateName: "",
        senderId: "",
        templateId: "",
        smsBody: "",
        selectedFields: [],
      });
      setVariables([]);
      setFileData([]);
      setButtonDisabled(true);
    } else {
      const template = templates.find(
        (template) => template.templateName === selectedTemplateName
      );
      if (template) {
        setFormData({
          ...formData,
          templateName: template.templateName,
          senderId: template.senderId,
          templateId: template.templateId,
          smsBody: template.smsBody,
          selectedFields: [], // Clear selected fields when template changes
        });
        setVariables(template.variables);
        setButtonDisabled(false);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      // If no file is selected (canceled), reset the fileData and related states
      setFormData({
        ...formData,
        csvFile: null,
      });
      setFileData([]);
      return;
    }

    setFormData({
      ...formData,
      csvFile: file,
    });

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = Papa.parse(e.target.result, { header: true });
      const keys = csvData.data.length > 0 ? Object.keys(csvData.data[0]) : [];
      setFileData(keys);
    };
    reader.readAsText(file);
  };

  const handleFieldSelection = (variable, field) => {
    const updatedFields = [...formData.selectedFields];
    const existingIndex = updatedFields.findIndex(
      (item) => item.variable === variable
    );

    if (existingIndex >= 0) {
      updatedFields[existingIndex] = { variable, fileField: field };
    } else {
      updatedFields.push({ variable, fileField: field });
    }

    setFormData({
      ...formData,
      selectedFields: updatedFields,
    });
  };

  const handleDownloadCsv = async (data) => {
    downloadFile(
      {
        index: data.index,
        fileName: data.sms.file_name,
        uploadName: data.sms.upload_name,
        uploadId: data.sms.id,
        downloadType: "CSVFile",
      },
      token,
      dispatch
    );
    return;
  };
  const handleDownloadLogFile = async (data) => {
    downloadFile(
      {
        index: data.index,
        fileName: data.sms.logFileName,
        uploadName: data.sms.logFileName,
        uploadId: data.sms.id,
        downloadType: "LogFile",
      },
      token,
      dispatch
    );
    return;
  };
  const handleRequestLogFile = async (data) => {
    reqLogFile(
      {
        index: data.index,
        uploadId: data.sms.id,
      },
      token,
      dispatch
    );
    return;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sendSMS(formData, token, dispatch);
  };

  useEffect(() => {
    // Set button disabled state based on whether all variables are selected
    const allVariablesSelected = variables.every((variable) =>
      formData.selectedFields.some((field) => field.variable === variable)
    );
    setButtonDisabled(!allVariablesSelected || !formData.templateName);
  }, [formData.selectedFields, variables, formData.templateName]);

  // useEffect(() => {
  //   console.log(formData);
  // }, [formData]);
  // useEffect(() => {
  //   console.log("variables", variables);
  // }, [variables]);

  const fetchSmsData = async (token, dispatch) => {
    await getSmsData(token, dispatch);
    // setSmsData(data);
    return;
  };

  useEffect(() => {
    // console.log("Getting Sms Data");
    // fetchSmsData(token, dispatch);
    getSmsData(token, dispatch);
    // console.log("Sms Data Fetched");
  }, []);

  if (updateSms) {
    fetchSmsData(token, dispatch);
  }

  // useEffect(() => {
  //   // console.log("sms Data", smsData);
  // }, [smsData]);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="section">
      <Container>
        {loading && <Loader />}
        <Row className="gy-5">
          <Col xs={12}>
            <form onSubmit={handleSubmit} className="auth-card card-lg mx-auto">
              <div className="auth-header">SMS Sender</div>
              <div className="auth-body">
                <Row className="gy-3">
                  <Col xs={12}>
                    <div className="form-group">
                      <label>Select SMS Template</label>
                      <select
                        name="template"
                        id="template"
                        required
                        onChange={handleTemplateChange}
                        value={formData.templateName}
                      >
                        <option value="">Choose SMS Template</option>
                        {templates.map((template) => (
                          <option
                            key={template.templateName}
                            value={template.templateName}
                          >
                            {template.templateName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <label>Sender ID:</label>
                      <input
                        type="text"
                        name="senderId"
                        value={formData.senderId}
                        onChange={handleInputChange}
                        disabled={!!formData.templateName}
                        required
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <label>Template ID:</label>
                      <input
                        type="text"
                        name="templateId"
                        value={formData.templateId}
                        onChange={handleInputChange}
                        disabled={!!formData.templateName}
                        required
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <label>SMS Body:</label>
                      <textarea
                        name="smsBody"
                        value={formData.smsBody}
                        onChange={handleInputChange}
                        disabled={!!formData.templateName}
                        required
                      ></textarea>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <label>CSV File</label>
                      <input
                        type="file"
                        accept=".csv"
                        className="form-control form-control-lg"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    {variables.length > 0 && fileData.length > 0 && (
                      <div>
                        {variables.map((variable, index) => (
                          <div key={variable} className="selectedFieldDiv">
                            <h4>Select field to use as {variable}:</h4>
                            {fileData.map((field, i) => (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={variable}
                                  value={field}
                                  id={`variable${field}${index}`}
                                  onChange={() =>
                                    handleFieldSelection(variable, field)
                                  }
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`variable${field}${index}`}
                                >
                                  {field}
                                </label>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </Col>
                  <Col xs={12}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={buttonDisabled}
                    >
                      Send SMS
                    </button>
                  </Col>
                </Row>
              </div>
            </form>
          </Col>
          <Col xs={12}></Col>
        </Row>
        <div className="auth-card card-full">
          <div className="auth-header">Recently Sended SMS List</div>
          <div className="auth-body">
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sr no</th>
                        <th>File name</th>
                        <th>SenderId</th>
                        <th>Sms Body</th>
                        <th>Template</th>
                        <th>Download CSV</th>
                        <th>Download LogFile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentSms.map((sms, index) => {
                        const templateName =
                          sms.template_id === "66bf5529d6fc052bf3008442"
                            ? "Allotment SMS"
                            : "Refund SMS";

                        const createdAt = new Date(`${sms.created_at}`);
                        const diffInMilliseconds =
                          currentTime.getTime() - createdAt.getTime();
                        const timeDifferenceInMinutes =
                          diffInMilliseconds / 60000;
                        // console.log(timeDifferenceInMinutes);
                        return (
                          <tr key={index}>
                            <td>{srNo++}</td>
                            <td>{sms.file_name}</td>
                            <td>{sms.sender_id}</td>
                            <td>
                              {expandedRows[index]?.smsBody
                                ? sms.smsBody
                                : `${sms.smsBody.substring(0, 120)}`}
                              {sms.smsBody.length > 120 && (
                                <span
                                  className="read-more"
                                  onClick={() =>
                                    toggleReadMore(index, "smsBody")
                                  }
                                >
                                  {expandedRows[index]?.smsBody
                                    ? "Read Less"
                                    : "Read More"}
                                </span>
                              )}
                            </td>
                            <td>{templateName}</td>
                            <td>
                              {sms.downloadingCsvLoading ? (
                                <button className="btn btn-sm btn-dark">
                                  Downloading...
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-dark"
                                  onClick={() =>
                                    handleDownloadCsv({ index, sms })
                                  }
                                >
                                  Download CSV
                                </button>
                              )}
                            </td>
                            <td>
                              {(() => {
                                if (timeDifferenceInMinutes > 5) {
                                  if (sms.logFileCreated === 0) {
                                    return (
                                      <button
                                        className="btn btn-sm btn-dark"
                                        onClick={() =>
                                          handleRequestLogFile({ index, sms })
                                        }
                                      >
                                        Request LogFile
                                      </button>
                                    );
                                  } else if (sms.logFileCreated === 2) {
                                    return (
                                      <button className="btn btn-sm btn-dark">
                                        Generating Log File
                                      </button>
                                    );
                                  } else if (sms.logFileCreated === 1) {
                                    return sms.downloadingLogFile ? (
                                      <button className="btn btn-sm btn-dark">
                                        Downloading...
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-sm btn-dark"
                                        onClick={() =>
                                          handleDownloadLogFile({ index, sms })
                                        }
                                      >
                                        Download LogFile
                                      </button>
                                    );
                                  }
                                } else {
                                  const remainingMinutes =
                                    5 - timeDifferenceInMinutes;
                                  return (
                                    <button
                                      className="btn btn-sm btn-dark"
                                      disabled
                                    >
                                      Request LogFile (Available in{" "}
                                      {remainingMinutes.toFixed(2)} min)
                                    </button>
                                  );
                                }
                              })()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="mt-4">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(
                      Array.isArray(smsData) ? smsData.length / smsPerPage : 0
                    )}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};
