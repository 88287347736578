import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingInfo } from "../actions/Auth";
import { Col, Row } from "react-bootstrap";

const PendingInfoPage = () => {
  const { pendingData, lastUploadData } = useSelector((state) => state.auth);
  //   console.log(pendingData);
  //   console.log(lastUploadData);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchPendingInfo(token, dispatch);
  }, []);

  return (
    <Row className="gy-5">
      <Col xs={12}>
        <div className="auth-card card-full">
          <div className="auth-header">Pending CSV</div>
          <div className="auth-body">
            <div className="table-responsive">
              {pendingData.length > 0 ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Total E-Mail</th>
                      <th>Sended E-Mail</th>
                      <th>Failed E-Mail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingData.map((file) => (
                      <tr key={file.id}>
                        <td>{file.file_name}</td>
                        <td>{file.total_email}</td>
                        <td>{file.sended_emails}</td>
                        <td>{file.failed_emails}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-data-message">
                  <p>No pending files available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12}>
        <div className="auth-card card-full">
          <div className="auth-header">Recent CSV</div>
          <div className="auth-body">
            <div className="table-responsive">
              {lastUploadData.length > 0 ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Total E-Mail</th>
                      <th>Sended E-Mail</th>
                      <th>Failed E-Mail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastUploadData.slice(0, 2).map((file) => (
                      <tr key={file.id}>
                        <td>{file.file_name}</td>
                        <td>{file.total_email}</td>
                        <td>{file.sended_emails}</td>
                        <td>{file.failed_emails}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-data-message">
                  <p>No recent files available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PendingInfoPage;
