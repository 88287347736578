import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
  isTokenAvailable: localStorage.getItem("token") ? true : false,
  pendingData: [],
  lastUploadData: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setToken(state, value) {
      state.token = value.payload;
    },
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setIsTokenAvailable(state, value) {
      state.isTokenAvailable = value.payload;
    },
    setPendingData(state, value) {
      state.pendingData = value.payload;
    },
    setLastUploadData(state, value) {
      state.lastUploadData = value.payload;
    },
  },
});
export const {
  setToken,
  setLoading,
  setIsTokenAvailable,
  setPendingData,
  setLastUploadData,
} = authSlice.actions;
export default authSlice.reducer;
