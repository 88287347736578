import { apiConnector } from "../services/apiConnector";
import { emailsEndpoints } from "../services/apis";
import toast from "react-hot-toast";
import { setEmails, setLoading as setEmailLoading } from "../slices/emailSlice";
import {
  setLoading as setUploadLoading,
  setUpdateEmails,
} from "../slices/uploadSlice";

const {
  GET_RECENTLY_SENDED_EMAILS,
  SEND_EMAILS,
  GET_RECENTLY_SENDED_EMAILS_COUNT,
} = emailsEndpoints;

export const uploadFile = async (formData, token, dispatch) => {
  try {
    dispatch(setUploadLoading(true));

    // const newObj = {
    //   csvFile: file,
    //   from: from,
    //   subject: subject,
    //   replyTo: replyTo,
    //   variables: selectedFields,
    //   body: body,
    // };
    console.log("fromData", formData);

    // dispatch(setUploadLoading(false));
    const response = await apiConnector("POST", SEND_EMAILS, formData, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
    // console.log(response);
    if (!response.data.success) {
      toast.error(response.data.message);
    }
    if (response.data.success) {
      toast.success(response.data.message);
    }
    dispatch(setUploadLoading(false));
    dispatch(setUpdateEmails(true));
    return true;
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error.message);
    }
    console.error("Error sending emails:", error);
    dispatch(setUploadLoading(false));
    return false;
  }
};

export const fetchEmails = async (token, dispatch, currentPage) => {
  try {
    dispatch(setEmailLoading(true));
    dispatch(setUpdateEmails(false));
    // console.log(token);
    const response = await apiConnector(
      "GET",
      `${GET_RECENTLY_SENDED_EMAILS}/${currentPage}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    // console.log(response);

    if (!response?.data?.success) {
      setTimeout(() => {
        toast.error(response?.data?.message);
      }, 500);
    }
    if (response?.data?.success) {
      dispatch(setEmails(response?.data?.data));
      // setTimeout(() => {
      //   toast.success(response?.data?.message);
      // }, 500);
    }
    dispatch(setEmailLoading(false));
  } catch (error) {
    if (error?.response?.data?.message) {
      if (error.response.data.message !== "Jwt token is expired") {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error(error.message);
    }
    console.error("Error fetching emails:", error);
    dispatch(setEmailLoading(false));
  }
};

export const fetchEmailsCount = async (token, dispatch, setTotalEmails) => {
  try {
    // dispatch(setEmailLoading(true));
    // dispatch(setUpdateEmails(false));
    // console.log(token);
    const response = await apiConnector(
      "GET",
      `${GET_RECENTLY_SENDED_EMAILS_COUNT}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    // console.log(response);

    if (!response?.data?.success) {
      setTimeout(() => {
        toast.error(response?.data?.message);
      }, 500);
    }
    if (response?.data?.success) {
      console.log(response.data.data);
      setTotalEmails(response?.data?.data);
      // setTimeout(() => {
      //   toast.success(response?.data?.message);
      // }, 500);
    }
    // dispatch(setEmailLoading(false));
  } catch (error) {
    if (error?.response?.data?.message) {
      if (error.response.data.message !== "Jwt token is expired") {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error(error.message);
    }
    console.error("Error fetching emails:", error);
    // dispatch(setEmailLoading(false));
  }
};
