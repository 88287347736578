import { combineReducers } from "@reduxjs/toolkit";
import emailSlice from "../slices/emailSlice";
import uploadSlice from "../slices/uploadSlice";
import authSlice from "../slices/authSlice";
import smsSlice from "../slices/smsSlice";

export const rootReducer = combineReducers({
  email: emailSlice,
  upload: uploadSlice,
  auth: authSlice,
  sms: smsSlice,
});
// export default rootReducer;
