import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { uploadFile } from '../actions';
import { uploadFile } from "../actions/Emails";
import Loader from "./Loader";
import Papa from "papaparse";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import { setLoading } from "../slices/uploadSlice";

const UploadForm = () => {
  const [formData, setFormData] = useState({
    csvFile: null,
    replyTo: "",
    from: "",
    subject: "",
    body: [""],
    selectedFields: [],
    delayInMs: 3000,
  });

  // const [file, setFile] = useState(null);
  // const [replyTo, setReplyTo] = useState("");
  // const [from, setFrom] = useState("");
  // const [subject, setSubject] = useState("");
  // const [body, setBody] = useState("");
  const [error, setError] = useState(""); // State for error message
  const [highlightedBody, setHighlightedBody] = useState(""); // State for highlighted text
  const [fileData, setFileData] = useState(null);
  const [variable, setVariable] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  // const [selectedFields, setSelectedFields] = useState([]);
  const [tabCount, setTabCount] = useState(1);
  const emailBodyLimit = 10;

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.upload);
  const { token } = useSelector((state) => state.auth);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      csvFile: file,
    });
  };

  const handleFieldSelection = (variable, field) => {
    const updatedFields = [...formData.selectedFields];
    const existingIndex = updatedFields.findIndex(
      (item) => item.variable === variable
    );

    if (existingIndex >= 0) {
      // Update existing entry
      updatedFields[existingIndex] = { variable, fileField: field };
    } else {
      // Add new entry
      updatedFields.push({ variable, fileField: field });
    }

    setFormData({
      ...formData,
      selectedFields: updatedFields,
    });
  };

  // async function extractUniqueVariables(text) {
  //   // Regular expression to match non-space text between ##
  //   const pattern = /#\$(\S+?)\$#/g;
  //   const matches = new Set();
  //   let match;

  //   // Use exec in a loop to find all matches
  //   while ((match = pattern.exec(text)) !== null) {
  //     matches.add(match[1]); // Add the text between ## to the Set
  //   }

  //   return Array.from(matches); // Convert the Set back to an array
  // }
  // const hasVariablesWithSpaces = (text) => {
  //   const pattern = /#\$(?:[^\s#]*\s+[^\s#]*)+\$#/g;
  //   return pattern.test(text);
  // };

  // const handleBodyVariable = async (e) => {
  //   e.preventDefault();
  //   setButtonDisable(true);
  //   setError("");
  //   setHighlightedBody("");
  //   setVariable([]);
  //   setFileData(null);

  //   if (formData.body.includes("#$") && formData.csvFile) {
  //     if (hasVariablesWithSpaces(formData.body)) {
  //       setError("Variables between #$ should not contain spaces.");
  //       setVariable([]);
  //       setHighlightedBody(highlightErrors(formData.body));
  //       setHighlightedBody(highlightErrors(formData.body));
  //       setButtonDisable(false);

  //       return;
  //     } else {
  //       const variables = await extractUniqueVariables(formData.body);
  //       // console.log("variables", variables);

  //       setVariable(variables);
  //       // console.log(variable);
  //       const reader = new FileReader();
  //       reader.onload = function (e) {
  //         const csvData = Papa.parse(e.target.result, { header: true });
  //         const keys = Object.keys(csvData.data[0]);
  //         setFileData(keys);
  //       };
  //       reader.readAsText(formData.csvFile);
  //       setButtonDisable(false);

  //       return;
  //     }
  //   }
  //   setButtonDisable(false);
  // };

  // const highlightErrors = (text) => {
  //   // Regular expression to match variables between #$ and $#
  //   const pattern = /#\$(.*?)\$#/g;

  //   return text.replace(pattern, (match, p1) => {
  //     if (/\s/.test(p1)) {
  //       // Highlight text with spaces
  //       return `#$<span style="background-color: red; color: white;">${p1}</span>$#`;
  //     }
  //     return match;
  //   });
  // };

  async function extractUniqueVariables(text) {
    // const pattern = /\^\^/g;
    let matches = new Set();
    let insideVariable = false;
    let variableContent = "";
    let startIndex = 0;
    // console.log(text);

    // Loop through each character in the text
    for (let i = 0; i < text.length; i++) {
      if (text[i] === "^" && text[i + 1] === "^") {
        if (!insideVariable) {
          // Starting a new variable
          insideVariable = true;
          startIndex = i + 2; // Skip the "^^"
        } else {
          // Ending a variable
          insideVariable = false;
          variableContent = text.slice(startIndex, i).trim(); // Extract the variable content
          matches.add(variableContent); // Add to the set
        }
        i++; // Skip the next "^"
      }
    }

    return Array.from(matches); // Convert the Set back to an array
  }

  const hasVariablesWithSpaces = (text) => {
    const pattern = /\^\^(.*?)\^\^/g;
    let match;

    while ((match = pattern.exec(text)) !== null) {
      const variable = match[1]; // Capture the content inside ^^
      if (/^\s|\s$/.test(variable) || /\s/.test(variable)) {
        return true; // Return true if the variable has leading/trailing spaces or internal spaces
      }
    }
    return false;
  };

  const handleBodyVariable = async (e, index) => {
    e.preventDefault();
    // console.log(e);

    if (
      e.target.name !== "textarea1" &&
      e.target.type !== "file" &&
      e.target.id !== "submitBtn"
    ) {
      const variables = await extractUniqueVariables(formData.body[index]);
      // console.log("variablessssssss", variables);
      if (
        variables.length !== variable.length ||
        !variables
          .slice()
          .sort()
          .every((val, index) => val === variable.slice().sort()[index])
      ) {
        if (formData.csvFile) {
          toast.error("Variables must be the same in all email body");
        }
      }

      return;
    }
    setButtonDisable(true);
    setError("");
    setHighlightedBody("");
    setVariable([]);
    setFileData(null);

    if (formData.body[0].includes("^^") && formData.csvFile) {
      if (hasVariablesWithSpaces(formData.body[0])) {
        setError(
          "Variables between ^^ should not contain leading, trailing, or internal spaces."
        );
        setVariable([]);
        setHighlightedBody(highlightErrors(formData.body[0]));
        setButtonDisable(false);

        return;
      } else {
        const variables = await extractUniqueVariables(formData.body[0]);
        setVariable(variables);

        const reader = new FileReader();
        reader.onload = function (e) {
          const csvData = Papa.parse(e.target.result, { header: true });
          const keys = Object.keys(csvData.data[0]);
          setFileData(keys);
        };
        reader.readAsText(formData.csvFile);
        setButtonDisable(false);

        return;
      }
    }
    setButtonDisable(false);
  };

  const highlightErrors = (text) => {
    const pattern = /\^\^(.*?)\^\^/g;
    return text.replace(pattern, (match, p1) => {
      if (/^\s|\s$/.test(p1) || /\s/.test(p1)) {
        // Highlight text with leading, trailing, or internal spaces
        return `^^<span style="background-color: red; color: white;">${p1}</span>^^`;
      }
      return match;
    });
  };

  const convertMsToTime = (ms) => {
    if (!ms || isNaN(ms)) return "--:--:--";

    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return (
      <span>
        <span>{hours}h </span>
        <span>{minutes}m </span>
        <span>{seconds}s </span>
      </span>
    );
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (formData.body.includes("^^") && variable.length <= 0) {
  //     handleBodyVariable(e);
  //     return;
  //   }
  //   uploadFile(formData, token, dispatch);
  // };
  const checkAllVariable = async () => {
    try {
      for (const element of formData.body) {
        // console.log(element);
        // console.log(variable);

        const variables = await extractUniqueVariables(element);
        // console.log(variables);
        if (variable.length === 0 && variables.length === 0) {
          return true;
        }
        if (
          variables.length !== variable.length ||
          !variables
            ?.slice()
            ?.sort()
            ?.every((val, index) => val === variable.slice().sort()[index])
        ) {
          toast.error("Variables must be the same in all email body");
          return false;
        }
      }
      return true;
    } catch (error) {
      console.log("Error while checking all variables", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    const data = await checkAllVariable();
    if (!data) {
      dispatch(setLoading(false));
      return;
    }
    const reader = new FileReader();
    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          resolve(e.target.result);
        };
        reader.onerror = reject;
        reader.readAsText(file);
      });
    };

    try {
      const csvContent = await readFile(formData.csvFile);
      const csvData = Papa.parse(csvContent, { header: true });

      const keys = Object.keys(csvData.data[0]);
      if (!keys.includes("Email")) {
        toast.error(`CSV file must contain an "Email" column`);
        dispatch(setLoading(false));
        return;
      }
    } catch (err) {
      console.error("Error reading CSV file", err);
      toast.error(
        `An error occurred while reading the CSV file please reload the page.`
      );
      dispatch(setLoading(false));
      return;
    }
    // Call handleBodyVariable to ensure validation is performed before submission
    await handleBodyVariable(e);
    if (variable.length > 0) {
      if (variable.length !== formData.selectedFields.length) {
        toast.error(
          "You Must have to select field from csv file to use as variable"
        );
        dispatch(setLoading(false));
        return;
      }
    }
    // Check if any errors were set during the validation
    if (error) {
      setButtonDisable(false);
      dispatch(setLoading(false));
      return; // Stop submission if there's an error
    }

    // Proceed with file upload and email sending
    uploadFile(formData, token, dispatch);
  };

  useEffect(() => {
    console.log(variable);
  }, [variable]);
  // useEffect(() => {
  //   console.log(highlightedBody);
  // }, [highlightedBody]);
  useEffect(() => {
    console.log(formData);
  }, [formData]);
  // useEffect(() => {
  //   console.log("variable", variable);
  // }, [variable]);
  // useEffect(() => {
  //   console.log("variable", fileData);
  // }, [fileData]);

  const handleTabCountChange = (e) => {
    const count = Number(e.target.value);
    setTabCount(count);

    // Adjust the size of the formData.body array based on the tabCount
    setFormData((prevData) => {
      const newBody = [...prevData.body]; // Clone the existing body array
      if (count > newBody.length) {
        // Add empty text areas if count is increased
        return {
          ...prevData,
          body: [...newBody, ...Array(count - newBody.length).fill("")],
        };
      } else {
        // Trim the array if count is decreased
        return {
          ...prevData,
          body: newBody.slice(0, count),
        };
      }
    });
  };

  // Handle the change for individual text areas
  const handleTextAreaChange = (index, value) => {
    setFormData((prevData) => {
      const newBody = [...prevData.body];
      newBody[index] = value; // Update the specific index in the body array
      return {
        ...prevData,
        body: newBody,
      };
    });
  };

  const textAreas = (
    <Tabs defaultActiveKey={0} id="email-tabs" className="emailTabs">
      {Array.from({ length: tabCount }, (_, index) => (
        <Tab key={index} eventKey={index} title={`Email Body ${index + 1}`}>
          <Form.Group className="form-group mt-3">
            <Form.Label>Email Body {index + 1}:</Form.Label>
            <Form.Control
              as="textarea"
              name={`textarea${index + 1}`}
              rows={4}
              cols={50}
              value={formData.body[index] || ""}
              onBlur={(e) => handleBodyVariable(e, index)}
              onChange={(e) => handleTextAreaChange(index, e.target.value)}
              placeholder={`Enter Email Body ${index + 1}`}
            />
          </Form.Group>
        </Tab>
      ))}
    </Tabs>
  );

  // useEffect(() => {
  //   console.log("textAreaValues", textAreaValues);
  // }, [textAreaValues]);
  return (
    <div className="auth-card card-lg mx-auto">
      <div className="auth-header">Email Sender</div>
      <div className="auth-body">
        {loading && <Loader />}
        <Row className="gy-3">
          <Col xs={12}>
            <div className="form-group">
              <label>From Email:</label>
              <input
                type="text"
                name="from"
                value={formData.from}
                onChange={handleInputChange}
                required
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="form-group">
              <label>Reply To</label>
              <input
                type="text"
                name="replyTo"
                value={formData.replyTo}
                onChange={handleInputChange}
                required
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="form-group">
              <label>Subject:</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="form-group">
              <label>Select Tab Count :</label>
              <select
                name="countB"
                value={tabCount}
                onChange={handleTabCountChange}
              >
                {Array.from({ length: emailBodyLimit }, (_, index) => (
                  <option value={index + 1}>{index + 1}</option>
                ))}
              </select>
            </div>
          </Col>

          <Col xs={12}>{textAreas}</Col>

          {/* <Col xs={12}>
            <div className="form-group">
              <label>Email Body:</label>
              <div className="textarea-wrapper">
                <textarea
                  value={formData.body[0]}
                  name="body"
                  // onChange={handleInputChange}
                  // onBlur={(e) => handleBodyVariable(e)}
                  required
                  rows="10"
                  cols="50"
                ></textarea>
                {error && <div className="error-message">{error}</div>}
              </div>
              {highlightedBody && (
                <div
                  className="highlighted-text"
                  dangerouslySetInnerHTML={{ __html: highlightedBody }}
                  style={{ whiteSpace: "pre-wrap" }} // Ensures line breaks are preserved
                ></div>
              )}
            </div>
          </Col> */}
          <Col xs={12}>
            <div className="form-group">
              <label>Delay In MS:</label>
              <input
                type="text"
                name="delayInMs"
                value={formData.delayInMs}
                onChange={handleInputChange}
                required
              />
              <div>
                <label>Converted Time: </label>
                <span>{convertMsToTime(formData.delayInMs)}</span>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <div className="form-group">
              <label>CSV File</label>

              <input
                type="file"
                onChange={handleFileChange}
                onBlur={(e) => handleBodyVariable(e)}
                className="form-control form-control-lg"
                accept=".csv"
              />
            </div>
            {variable.length > 0 && fileData && (
              <div>
                {variable.map((variable, index) => (
                  <div key={variable} className="selectedFieldDiv">
                    <h4>Select field to use as {variable}:</h4>
                    {fileData.map((field, i) => (
                      // <div className="form-group radioGroup" key={field}>
                      //   <input
                      //     type="radio"
                      //     id={`variable${field}${index}`}
                      //     name={variable}
                      //     value={field}
                      //     onChange={() => handleFieldSelection(variable, field)}
                      //     required
                      //   />
                      //   <label htmlFor={`variable${field}${index}`}>
                      //     {field}
                      //   </label>
                      // </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name={variable}
                          value={field}
                          id={`variable${field}${index}`}
                          checked={
                            formData.selectedFields.find(
                              (item) => item.variable === variable
                            )?.fileField === field
                          }
                          onChange={() => handleFieldSelection(variable, field)}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`variable${field}${index}`}
                        >
                          {field}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </Col>
          <Col xs={12}>
            {buttonDisable ? (
              <button type="button" className="btn btn-primary" disabled>
                Send Emails
              </button>
            ) : (
              <button
                type="button"
                id="submitBtn"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Send Emails
              </button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UploadForm;
