export const authEndpoints = {
  LOGIN: `${process.env.REACT_APP_API_KEY}login`,
  SIGNUP: `${process.env.REACT_APP_API_KEY}signup`,
  PENDING_INFO: `${process.env.REACT_APP_API_KEY}pending-info`,
  TEST: `${process.env.REACT_APP_API_KEY}test`,
};
export const emailsEndpoints = {
  GET_RECENTLY_SENDED_EMAILS: `${process.env.REACT_APP_API_KEY}emails`,
  GET_RECENTLY_SENDED_EMAILS_COUNT: `${process.env.REACT_APP_API_KEY}emailsCount`,
  SEND_EMAILS: `${process.env.REACT_APP_API_KEY}send-emails`,
};
export const smsEndpoints = {
  SEND_SMS: `${process.env.REACT_APP_API_KEY}send-sms`,
  GET_SMS: `${process.env.REACT_APP_API_KEY}getSmsData`,
  LOG_FILE_REQ: `${process.env.REACT_APP_API_KEY}logFileGenerate`,
  DOWNLOAD_FILE: `${process.env.REACT_APP_API_KEY}downloadFile`,
};
