import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  updateSms: false,
  smsData: [],
};

const smsSlice = createSlice({
  name: "sms",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setUpdateSMS(state, value) {
      state.updateSms = value.payload;
    },
    setSmsData(state, value) {
      // console.log("line no 20",value)
      state.smsData = value.payload;
    },
    setLoadingForDownloadCsv(state, value) {
      // console.log("line no 23", value);
      const copyOfSmsData = [...state.smsData];
      copyOfSmsData[value.payload.index].downloadingCsvLoading =
        value.payload.loading;
      state.smsData = copyOfSmsData;
    },
    setLoadingForDownloadLogFile(state, value) {
      // console.log("line no 26", value);
      const copyOfSmsData = [...state.smsData];
      copyOfSmsData[value.payload.index].downloadingLogFile =
        value.payload.loading;
      state.smsData = copyOfSmsData;
    },
    setLoadingForRequestingLogFile(state, value) {
      // console.log("line no 38", value);
      const copyOfSmsData = [...state.smsData];
      copyOfSmsData[value.payload.index].logFileCreated = value.payload.loading;
      if (value.payload.filename) {
        copyOfSmsData[value.payload.index].logFileName = value.payload.filename;
      }
      state.smsData = copyOfSmsData;
    },
  },
});

export const {
  setLoading,
  setUpdateSMS,
  setSmsData,
  setLoadingForDownloadCsv,
  setLoadingForDownloadLogFile,
  setLoadingForRequestingLogFile,
} = smsSlice.actions;
export default smsSlice.reducer;
