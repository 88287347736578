import { apiConnector } from "../services/apiConnector";
import { smsEndpoints } from "../services/apis";
import toast from "react-hot-toast";
import {
  setLoadingForDownloadLogFile,
  setLoadingForDownloadCsv,
  setLoadingForRequestingLogFile,
  setSmsData,
  setLoading as setSmsLoading,
  setUpdateSMS,
} from "../slices/smsSlice";
const { SEND_SMS, GET_SMS, LOG_FILE_REQ, DOWNLOAD_FILE } = smsEndpoints;

export const sendSMS = async (formData, token, dispatch) => {
  try {
    dispatch(setSmsLoading(true));
    // const newObj = {
    //   csvFile: file,
    //   from: from,
    //   subject: subject,
    //   replyTo: replyTo,
    //   variables: selectedFields,
    //   body: body,
    // };
    // console.log("fromData", formData);

    // dispatch(setSmsLoading(false));
    const response = await apiConnector("POST", SEND_SMS, formData, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
    // console.log("sms", response);
    if (!response.data.success) {
      toast.error(response.data.message);
    }
    if (response.data.success) {
      toast.success(response.data.message);
    }
    dispatch(setSmsLoading(false));
    dispatch(setUpdateSMS(true));
    return true;
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error.message);
    }
    console.error("Error sending sms:", error);
    dispatch(setSmsLoading(false));
    return false;
  }
};

export const getSmsData = async (token, dispatch) => {
  try {
    dispatch(setUpdateSMS(false));
    const response = await apiConnector("GET", GET_SMS, null, {
      Authorization: `Bearer ${token}`,
    });
    // console.log("response", response);
    if (!response?.data?.success) {
      setTimeout(() => {
        toast.error(response?.data?.message);
      }, 500);
    }

    if (response?.data?.success) {
      dispatch(setSmsData(response?.data?.data));
      setTimeout(() => {
        toast.success(response?.data?.message);
      }, 500);
    }
  } catch (error) {
    console.error("Error while fetching getSmsData", error);
    if (error?.response?.data?.message) {
      if (error.response.data.message !== "Jwt token is expired") {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error(error.message);
    }
    dispatch(setUpdateSMS(false));
  }
};

export const reqLogFile = async (data, token, dispatch) => {
  try {
    // console.log("data", data);

    dispatch(setLoadingForRequestingLogFile({ index: data.index, loading: 2 }));
    const response = await apiConnector(
      "GET",
      `${LOG_FILE_REQ}/${data.uploadId}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      {
        uploadId: data.uploadId,
      }
    );
    // console.log(response);
    if (!response?.data?.success) {
      dispatch(
        setLoadingForRequestingLogFile({
          index: data.index,
          loading: 0,
        })
      );
      setTimeout(() => {
        toast.error(response?.data?.message);
      }, 500);
    }

    if (response?.data?.success) {
      // dispatch(setSmsData(response?.data?.data));
      dispatch(
        setLoadingForRequestingLogFile({
          index: data.index,
          loading: 1,
          filename: response?.data?.filename,
        })
      );
      dispatch(setUpdateSMS(true));
      setTimeout(() => {
        // toast.success(response?.data?.message);
      }, 500);
    }
  } catch (error) {
    console.log("Error while requesting log file", error);
    toast.error(error.message);
    dispatch(setLoadingForRequestingLogFile({ index: data.index, loading: 0 }));
  }
};

export const downloadFile = async (data, token, dispatch) => {
  try {
    // console.log("Data", data);

    if (data.downloadType === "CSVFile") {
      dispatch(setLoadingForDownloadCsv({ index: data.index, loading: true }));
    } else {
      dispatch(
        setLoadingForDownloadLogFile({ index: data.index, loading: true })
      );
    }
    const response = await apiConnector(
      "GET",
      `${DOWNLOAD_FILE}/${data.uploadId}/${data.uploadName}`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      {
        uploadId: data.uploadId,
        filename: data.uploadName,
      }
    );
    // console.log(response);
    if (response.status === 200) {
      // Create a blob from the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = data.uploadName;
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.success("File downloaded successfully");
    } else {
      console.log("Error with status code", response.status);
      toast.error(response?.data?.message || "Failed to download file");
    }
    if (data.downloadType === "CSVFile") {
      dispatch(setLoadingForDownloadCsv({ index: data.index, loading: false }));
    } else {
      dispatch(
        setLoadingForDownloadLogFile({ index: data.index, loading: false })
      );
    }
  } catch (error) {
    console.log("Error while downloading file", error);
    if (error?.response?.data?.message) {
      if (error.response.data.message !== "Jwt token is expired") {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error(error.message);
    }
    if (data.downloadType === "CSVFile") {
      dispatch(setLoadingForDownloadCsv({ index: data.index, loading: false }));
    } else {
      dispatch(
        setLoadingForDownloadLogFile({ index: data.index, loading: false })
      );
    }
  }
};
